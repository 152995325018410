@tailwind base;

@layer base {
    html {
        @apply text-neutral-800;
    }
    html.dark {
        @apply text-neutral-50;
        @apply bg-neutral-800;
    }
}

@tailwind components;
@tailwind utilities;

table{
    overflow-y:scroll;
    height:420px;
    display:block;
 }